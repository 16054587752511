import './src/assets/css/additional.css'
import 'tailwindcss/dist/base.min.css'
import './src/assets/css/hamburger.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faSearch, faPhone, faMapMarkerAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'

const { GATSBY_RECAPTCHA_SITE_KEY, GATSBY_ENABLE_REDIRECT } = process.env

library.add(fab, faEnvelope, faSearch, faPhone, faMapMarkerAlt, faSpinner)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
	  await import('intersection-observer')
  }
}

export const onInitialClientRender = () => {
  if (GATSBY_ENABLE_REDIRECT && GATSBY_ENABLE_REDIRECT !== 'false') {
    if (!location.hostname.includes(GATSBY_ENABLE_REDIRECT)) {
      window.location.href = `https://${GATSBY_ENABLE_REDIRECT}`
    }
  }

  window['grecaptcha'].ready(function () {
    window.runRecaptcha = async () => {
      const token = await window['grecaptcha'].execute(GATSBY_RECAPTCHA_SITE_KEY, {
        action: 'submit',
      })
      console.log('token', token)
      window['grecaptcha'].token = token
      return token
    }
  })
}
